<template>
  <div>
    <ExamplesBar/>
    <v-container fluid class="mx-auto">
      <carousel-3d :controls-visible="false" :autoplay="true" :autoplay-timeout="8000" :border="0" :disable3d="true" :space="365">
        <slide :index="0" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/armory3d/old_island/index.html"
                  target="_blank"
                  >
                  <p>4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutube src="https://www.youtube.com/watch?v=7ektZRfBTsc" />
              </div>
            </div>
        </slide>
      </carousel-3d>
      <carousel-3d :controls-visible="false" :autoplay="true" :autoplay-timeout="8000" :border="0" :disable3d="true" :space="365">
        <slide :index="0" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/armory3d/old_island/index.html"
                  target="_blank"
                  >
                  <p>4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutube src="https://www.youtube.com/watch?v=dgbTpZaNyH4" />
              </div>
            </div>
        </slide>
      </carousel-3d>
      <carousel-3d :controls-visible="false" :autoplay="true" :autoplay-timeout="8000" :border="0" :disable3d="true" :space="365">
        <slide :index="0" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/armory3d/old_island/index.html"
                  target="_blank"
                  >
                  <p>4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutube src="https://www.youtube.com/watch?v=7ektZRfBTsc" />
              </div>
            </div>
        </slide>
      </carousel-3d>
    </v-container>
  </div>
</template>
<script>
import ExamplesBar from '@/components/controls_vue/ExamplesBar.vue';
import { Carousel3d, Slide } from 'vue-carousel-3d';
import { LazyYoutube } from "vue-lazytube";
  export default {
    name: 'Armory3D',
    components: {
      Carousel3d,
      Slide,
      LazyYoutube,
      ExamplesBar,
    },
    data: () => ({
      length: 3,
      onboarding: 0,
    }),

    methods: {
      next () {
        this.onboarding = this.onboarding + 1 === this.length
          ? 0
          : this.onboarding + 1
      },
      prev () {
        this.onboarding = this.onboarding - 1 < 0
          ? this.length - 1
          : this.onboarding - 1
      },
    },
  }
</script>
<style scoped>
#carousel {
  background-color: transparent;
}
#link {
    top: 0;
    left: 0;
    overflow: inherit;
    background: transparent; /* Цвет фона */
}
#youtube {
    top: 10px;
    position: relative;
    padding-bottom: 56.25%; /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
    height: 0;
    overflow: hidden;
    background-color: transparent;

}
p {
  position: absolute;
  color: #265787;
  margin: 20px;
  opacity: 1;
  transition: opacity 0.6s;
 font-size: 15pt;
 font-family: "ToxicPowers";
}
#back_slide {
  background-color: transparent;
  padding: 10px;

}
@font-face {
font-family: "ToxicPowers";
src: url("/preloader/ToxicPowers.ttf");
/* font-style: normal; */
/* font-weight: normal; */
}
</style>
