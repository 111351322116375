<template>
  <v-app-bar
    app
    color="rgba(216, 216, 216, 0.7)"
    flat
    dense
    bottom
  >
    <v-tabs
      centered
      class="ml-auto"
      color="lime"
      dark
    >
    <v-tab to="/verge3d" tag="button" id="tabb">
      Verge3D
    </v-tab>
    <v-tab to="/armory3d" tag="button" id="tabb">
      Armory3D
    </v-tab>
    <v-tab to="/blend4web" tag="button" id="tabb">
      Blend4Web
    </v-tab>
<!--     <v-tab to="/playcanvas" tag="button" id="tabb">
      PlayCanvas
    </v-tab> -->



    </v-tabs>
  </v-app-bar>

</template>
<script>
// @ is an alias to /src

export default {
  name: 'ExamplesBar',
  data: () => ({
    drawer: null

  }),
}
</script>
<style scoped>
#tabb {
	/* position: absolute;
	padding: 20px; */
	color: #265787;
  /* color: cyan;   */
	opacity: 1;
	transition: opacity 0.6s;
	/* left: 0px;
	right: 0px;
	bottom: 25%; */
	font-family: "ToxicPowers";
	/* text-align: center;
	font-size: 32px; */
}

@font-face {
font-family: "ToxicPowers";
src: url("/preloader/ToxicPowers.ttf");
/* font-style: normal; */
/* font-weight: normal; */
}
</style>
